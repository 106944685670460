import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
import './lib/jquery.cookie';

$(document).foundation();

$(function() {
  // スクロールするとフェードイン
  ScrollReveal().reveal(".js_reveal",{
    duration: 2000,
    scale: 1,
    distance: '20px',
    mobile: false
  });
  ScrollReveal().reveal(".js_revealHead",{
    duration: 2000,
    scale: 1,
    mobile: false
  });

  var getDevice = (function(){
    var ua = navigator.userAgent;
    if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
      return 'sp';
    }else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
      return 'tab';
    }else{
      return 'other';
    }
  })();
  if( getDevice !== 'other' ){
    //PC以外は以下のclassを実行
    $(".js_reveal").css("visibility", "visible");
    $(".js_revealHead").css("visibility", "visible");
  }




});
